<template>
    <div class="edit-plan">
        <v-row v-if="ready">
            <v-col cols="12" md="7" order="2" order-md="1">
                <Field-Settings v-if="!showTabs" @save="save" :draw="draw" :add="add" @updateSelect="updateSelect" :select="select" ref="settings" @reset="reset" @combine="combine" @draw="drawField" @add="addField" @next="nextField" @finish="finishFields" :fields="fields" :planYear="plan.year" :planName="plan.name" @editField="editField" @deleteField="deleteField" @nameChange="nameChange" @cropChange="cropChange" @magnify="magnify" :customer="plan.customer"  />
                <Field-Tabs v-else @done="fieldReady" @exit="exit" :field="field" />
            </v-col>
            <v-col cols="12" md="5" order="1" order-md="2">
                <Map @prospectCoords="prospectCoords" @pushField="pushField" ref="map" :draw="draw" :add="add" :select="select" @geoJsonReady="mapReady = true" :mapFields="mapFields" :mapCoords="mapCoords" @editField="editMapField" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import Map from "@/components/Map"
import FieldSettings from "@/components/FieldSettings"
import FieldTabs from "@/components/FieldTabs"
export default {
    name: "EditPlan",
    data() {
        return {
            mapCoords: "",
            draw: false,
            add: false,
            startingFields: [],
            select: false,
            dev: false,
            field: "",
            fields: [],
            step: 1,
            mapReady: false,
            ready: false,
            showTabs: false,
            planFields: {},
            fieldValues: "",
            deletedFields: [],
            // plan: {
            //     year: new Date().getFullYear(),
            //     customerid: "",
            //     name: ""
            // }
        }
    },
    components: {
        Map,
        FieldSettings,
        FieldTabs
    },
    async created() {
        if (this.token) {
            await this.startPlan()
        }
    },
    watch: {
        async token(val) {
            await this.startPlan()
        }
    },
    computed: {
        ...mapState("customer", ["customer"]),
        ...mapState("user", ["token"]),
        ...mapState("plan", ["plan"]),
        ...mapState("product", ["products", "cropValues", "archivedProducts"]),
        ...mapGetters("product", ["cornTraits", "soybeanTraits"]),
        
    },
    methods: {
        async startPlan() {
            await Promise.all([this.$store.dispatch("plan/getPlan", this.$route.params.id), this.$store.dispatch("product/getCRMProducts")])
            await Promise.all([this.$store.dispatch("plan/getPlan", this.$route.params.id), this.$store.dispatch("product/getArchivedCRMProducts")])
            this.fieldValues = JSON.parse(this.plan.defaultSettings)
            this.$store.commit("product/setCropValues", JSON.parse(this.plan.cropValues)) 
            this.fields = this.formatFields(this.plan.fields.items)
            this.startingFields = JSON.parse(JSON.stringify(this.fields))
            this.mapFields = this.getMapFeatures(this.fields)

            this.fields = this.fields.map(item => {
                item.popRate = item.population ? item.population : item.rate
                item.calcUnits = this.calculate(item);
                return item
            }).sort((a,b) => a.fieldId > b.fieldId ? 1 : a.fieldId < b.fieldId ? -1 : 0)
            this.field = this.fields[0]

            this.ready = true
        },
        checkForInitial(fields) {
            return fields.every(item => !isNaN(item.name))
        },
        formatFields(fields) {
            return fields.map(item => {
                if (item.crop === "wheat") {
                    item.crop = "Spring Wht"
                }
                else if (item.crop === "sunflower") {
                    item.crop = "Sunflowers"
                }
                else if (item.crop === "sugarbeets") {
                    item.crop = "Sugar Beets"
                }
                else {
                    item.crop = this.capitalize(item.crop)
                }
                return item
            }).sort((a,b) => a.fieldId > b.fieldId ? 1 : a.fieldId < b.fieldId ? -1 : 0)
            
        },
        capitalize(val) {
            return `${val.slice(0,1).toUpperCase()}${val.slice(1)}`
        },
        getMapFeatures(val) {
            return val.map(item => {
                if (item.geoJson === "null") {
                    return {
                        type: "Feature",
                        geometry: null,
                        properties: null
                    }
                }
                else {
                    return JSON.parse(item.geoJson)
                }
            })
        },
        async prospectCoords(val) {
            let [prospect] = await this.$store.dispatch("plan/getGrowerData", {
                lat: val.lat,
                lon: val.lng,
                radius: 1
            })
            if (prospect) {
                let field = prospect.Fields[0].Geo.features[0]
                let fieldVals = JSON.parse(JSON.stringify(this.fieldValues))
                let newField = {
                    name: "new field",
                    brand: "Peterson Farms Seed",
                    done: false,
                    added: true,
                    crop: "",
                    trait: "",
                    acres: field.properties.acres,
                    drainage: fieldVals.drainage,
                    irrigation: fieldVals.irrigation,
                    tillage: fieldVals.tillage,
                    productSettings: {
                        brand: "Peterson Farms Seed",
                        product: ""
                    },
                    settings: fieldVals
                }
                this.fields.unshift(newField)
                this.$refs.map.addNewField(field)
                this.reIndex()
            }
            else {
                this.$swal({
                    toast: true,
                    icon: "error",
                    customClass: "farm-market-field",
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    title: "No field found",
                    timerProgressBar: true
                })
            }
        },
        updateSelect() {
            this.select = !this.select
            this.add = false
            this.draw = false
        },
        addField() {
            this.add = !this.add
            this.draw = false
            this.select = false
            this.$refs.map.toggleAdd()
        },
        drawField() {
            this.draw = !this.draw
            this.add = false
            this.select = false
            this.$refs.map.toggleDraw()
        },
        pushField(acres) {
            let fieldVals = JSON.parse(JSON.stringify(this.fieldValues))
            let newField = {
                name: "new field",
                brand: "Peterson Farms Seed",
                crop: "",
                trait: "",
                done: false,
                added: true,
                acres,
                drainage: fieldVals.drainage,
                irrigation: fieldVals.irrigation,
                tillage: fieldVals.tillage,
                productSettings: {
                    brand: "Peterson Farms Seed",
                    product: ""
                },
                settings: fieldVals
            }
            this.fields.unshift(newField)
            this.reIndex()
            // this.draw = false
        },
        checkProp(val, propName, prop1, prop2) {
            return path([propName, prop1, prop2], val) ? val.prop1.prop2 : null
        },
        reset() {
            this.fields = JSON.parse(JSON.stringify(this.startingFields))
            this.$refs.map.reset()
        },
        combine(val) {
            val.forEach(item => {
                this.deletedFields.push(item.id)
                let ind = this.fields.findIndex(field => {
                    return field.fieldId === item.fieldId
                })
                this.fields.splice(ind, 1)
            })
            let fieldVals = JSON.parse(JSON.stringify(this.fieldValues))
            let newField = val.reduce((acc, curr) => {
                acc.acres = acc.acres + curr.acres
                return acc
            }, {
                name: (val.map(item => item.name)).join(","),
                done: false,
                acres: 0,
                crop: "",
                trait: "",
                added: true,
                drainage: fieldVals.drainage,
                irrigation: fieldVals.irrigation,
                tillage: fieldVals.tillage,
                brand: "Peterson Farms Seed",
                productSettings: {
                    brand: "Peterson Farms Seed",
                    product: ""
                },
                settings: JSON.parse(JSON.stringify(this.fieldValues))
            })
            newField.acres = this.$refs.map.combine(val)
            this.fields.unshift(newField)
            this.reIndex()
        },
        exit(val) {
            this.showTabs = false
            this.mapCoords = "exit"
        },
        calculate(item) {
            if (item.crop.toLowerCase() === "soybeans") {
                return Math.ceil((item.population / 140000) * item.acres)
            }
            else if (item.crop.toLowerCase() === "corn") {
                return Math.ceil(((item.variableRate ? item.avgRate : item.rate) / 80000) * item.acres)
            }
        },
        reduceFields(fields) {
            return Object.values(fields.reduce((acc, curr) => {
                if (acc[curr.product]) {
                    acc[curr.product].acres = acc[curr.product].acres + curr.acres
                    if (acc[curr.product].calcUnits) {
                        acc[curr.product].calcUnits =  acc[curr.product].calcUnits + curr.calcUnits
                    }
                }
                else {
                    acc[curr.product] = {
                        product: curr.product,
                        brand: curr.brand,
                        crop: curr.crop,
                        trait: curr.trait,
                        acres: curr.acres,
                        calcUnits: curr.calcUnits,
                        orderUnits: curr.orderUnits
                    }
                }
                return  acc
            }, {})).filter(item => item.product)
        },
        productReport(fields) {
            let all = [... new Set(fields.map(item => item.crop))]
            let cropFields = all.map(item => {
                return fields.filter(fieldItem => {
                    return fieldItem.crop === item
                })
            })
            let res = cropFields.map(item => {
                return this.reduceFields(item)
            })
            return [].concat(...res)
        },
        grabCropValues(val) {
            this.step++
        },
        nextField() {
            let field = JSON.parse(JSON.stringify(this.field))
            field.geoJson = JSON.stringify(this.$refs.map.customerGeo.features[field.fieldId])
            field.crop = this.checkFieldType(field.crop)
            this.$store.dispatch("plan/updateField", field)
            let next = this.fields.filter(item => !item.done && (item.crop === "Corn" || item.crop === "Soybeans" || item.crop === "Peas" || item.crop === "Spring Wht" ))
            if (!next.length) this.showTabs = false
            else this.editField(next[0]) 
        },
        checkFields() {
            if (this.remainingFields.length) return false
            else return true
        },
        checkFieldType(val) {
            let final = (val.toLowerCase()).replace(/ /g, '')
            if (final === "pas/grasoth") {
                return "pasture"
            }
            else if (final === "springwht") {
                return "wheat"
            }
            else if (final === "sunflowers") {
                return "sunflower"
            }
            else if (final === "") {
                return "other"
            }
            else return final
        },
        finalizeField() {
            let fields = JSON.parse(JSON.stringify(this.fields))
            return fields.map((item, ind) => {
                item.geoJson = JSON.stringify(this.$refs.map.customerGeo.features[ind])
                item.crop = this.checkFieldType(item.crop)
                return item
            })
        },
        saveInitialFields(allFields, customerGeo) {
            let fieldsCopy = JSON.parse(JSON.stringify(allFields))
            return fieldsCopy.map((item, ind) => {
                item.geoJson = JSON.stringify(customerGeo.features[ind])
                item.crop = this.checkFieldType(item.crop)
                item.calcUnits = this.calculate(item)
                if (item.yieldEnvironment) {
                    if (!item.yieldEnvironment.length) {
                        delete item.yieldEnvironment
                    }
                }
                return item
            })
        },
        async savePlan(fields, mapData) {
            let final = this.saveInitialFields(fields, mapData)
            // let products = final.map(item => item.product)
            let data = {
                name: this.cropValues.name,
                fields: final,
                planCustomerId: this.$route.params.id,
                planGrowerId: this.customer.pfs_farmmarketid,
                year: this.plan.year
            }
            let planId = await this.$store.dispatch("plan/createFinalPlan", data)
            this.$router.push(`/plans/${planId}/edit`)
        },
        async finishSetup() {
            if (!this.checkFields() && !this.dev) return
            if (this.dev) {
                this.setProducts()
            }
            let final = this.finalizeField()
            let products = final.map(item => item.product)
            let data = {
                name: this.cropValues.name,
                fields: final,
                planCustomerId: this.$route.params.id,
                planGrowerId: this.customer.pfs_farmmarketid,
                year: this.plan.year
            }
            let planId = await this.$store.dispatch("plan/createFinalPlan", data)
            this.$router.push(`/plans/${planId}`)
        },
        fieldReady(fieldName) {
            let field = this.fields.find(item => item.name === fieldName)
            field.done = true
            this.nextField()
            // this.showTabs = false
        },
        async grabPlanFields() {
            let [fields, mapFields] = await Promise.all([this.$store.dispatch("plan/getGrowerFields", this.customer.pfs_farmmarketid), this.$store.dispatch("plan/getGrowerGeoData", this.customer.pfs_farmmarketid)])
            this.planFields = {
                fields,
                mapFields
            }
        },
        updateOrderUnits(item, products) {
            let find = this.plan.products.find(product => product.product === item.product && product.crop === item.crop)
            if (find) {
                if (!find.orderUnits) return find.calcUnits
                else return find.orderUnits
            }
            else return 0
        },
        updateMaturity(item) {
            if (item.brand === "Peterson Farms Seed") {
                let find = this.products.find(product => product.name === item.product)
                return find.pfs_maturity
            }
            else return null
        },
        async save(saveBtn = true) {
            // add, update and delete fields
            let initial = this.saveInitialFields(this.fields, this.$refs.map.customerGeo)
            let report = this.productReport(initial)
            if (!this.plan.products) this.plan.products = report
            let update = report.map(item => {
                item.orderUnits = this.updateOrderUnits(item)
                if(item.maturity) {
                    item.maturity = this.updateMaturity(item)
                }
                return item
            })
            let updateProducts = this.$store.dispatch("plan/updatePlanProducts", {
                id: this.plan.id,
                products: update
            })
            let addOrUpdate = Promise.all(initial.map(async (item, ind) => {
                item.planId = this.plan.id
                if (item.added) {
                    let field = await this.$store.dispatch("plan/createField", item)
                    this.fields[ind].id = field.id
                    delete this.fields[ind].added
                    return field
                }
                else {
                    return this.$store.dispatch("plan/updateField", item)
                }
            }))
            let deleted = Promise.all(this.deletedFields.map(item => this.$store.dispatch("plan/removeField", item)))
            let [finalUpdated, finalDeleted, finalProductsUpdated] = await Promise.all([addOrUpdate, deleted, updateProducts])
            if (saveBtn) this.$refs.settings.saveLoading = false
            else this.$refs.settings.finishLoading = false
        },
        async finishFields() {
            await this.save(false)
            this.$router.push(`/plans/${this.$route.params.id}`)
        },
        async finish(val) {
            this.step++
            // this.getFarmMarketData()
            // remove when we are ready for popup again
            let finalFields = this.setFields(this.planFields.fields, false)
            let mapData = this.setData(this.planFields.mapFields)
            await this.savePlan(finalFields, mapData)
        },
        setProducts() {
            this.fields.forEach(item => {
                item.done = true
            })
        },
        setData(data, colorChange = true) {
            // this.customerGeo = null
            this.customerGeo = data
            this.customerGeo.features.forEach((item,ind) => {
                item.properties.fieldId = ind
                item.properties.title = item.properties.title ? item.properties.title : `${ind + 1}`
                if (colorChange) {
                    item.properties.color = item.properties.crop === 'Field Corn' ? "#ffff00" : item.properties.crop === 'Soybeans' ? "#008000" : "#808080"
                }
            })
            return this.customerGeo
        },
        editField(val) {
            let ind = this.fields.findIndex(item => item.fieldId === val.fieldId)
            this.mapCoords = ind
            this.field = val
            this.showTabs = true
        },
        deleteField(val) {
            if (val.id) this.deletedFields.push(val.id)
            let ind = this.fields.findIndex(item => item.fieldId === val.fieldId)
            this.fields.splice(ind, 1)
            this.$refs.map.customerGeo.features.splice(ind, 1)
            this.reIndex()
        },
        reIndex() {
            this.fields.forEach((item, key) => {
                item.fieldId = key
            })
            this.$refs.map.customerGeo.features.forEach((item, key) => {
                item.properties.fieldId = key
            })
        },
        findFeature(val) {
            let ind = this.fields.findIndex(item => item.fieldId === val.fieldId)
            return {
                feature: this.$refs.map.customerGeo.features[ind],
                ind
            }
        },
        editCropInfo(item) {
            if (item.crop === "Corn") {
                item.trait = this.cornTraits[0]
                item.gossWilt = "Average"
                item.greensnap = "Low"
                item.yieldEnvironment = []
                item.rate = 32000
                item.variableRate = false
                item.rateRange = [20000, 30000]
                item.avgRate = 20000
                item.idc = null
                item.cyst = null
                item.phytophthora = null
                item.whiteMold = null
                item.population = null
            }
            else if (item.crop === "Soybeans") {
                item.trait = this.soybeanTraits[0]
                item.idc = "Average"
                item.cyst = "No"
                item.phytophthora = "Average"
                item.whiteMold = "Low"
                item.population = 140000
                item.gossWilt = null
                item.greensnap = null
                item.yieldEnvironment = null
                item.rate = null
            }
            else if (item.crop === "Peas") {
                item.trait = null
                item.gossWilt = null
                item.greensnap = null
                item.yieldEnvironment = null
                item.rate = null
                item.variableRate = false
                item.rateRange = null
                item.avgRate = null
                item.idc = null
                item.cyst = null
                item.phytophthora = null
                item.whiteMold = null
                item.population = null
                item.product = null
                item.rowSpacing = null
                item.brand = "Peterson Farms Seed"
            }
            else if (item.crop === "Spring Wht") {
                item.trait = null
                item.gossWilt = null
                item.greensnap = null
                item.yieldEnvironment = null
                item.rate = null
                item.variableRate = false
                item.rateRange = null
                item.avgRate = null
                item.idc = null
                item.cyst = null
                item.phytophthora = null
                item.whiteMold = null
                item.population = null
                item.yieldTarget = 100
                item.protein = 16
                item.product = null
                item.ph = null
                item.brand = "Peterson Farms Seed"
            }
            else {
                item.trait = null
                item.gossWilt = null
                item.greensnap = null
                item.yieldEnvironment = null
                item.rate = null
                item.variableRate = false
                item.rateRange = null
                item.avgRate = null
                item.idc = null
                item.cyst = null
                item.phytophthora = null
                item.whiteMold = null
                item.population = null
                item.product = null
                item.brand = null
            }
        },
        cropChange(val) {
            val.done = false
            this.editCropInfo(val)
            let { feature, ind } = this.findFeature(val)
            feature.properties.color = val.crop === "Corn" ? "#ffff00" : val.crop === "Soybeans" ? "#008000" : "#808080"
            feature.properties.crop = val.crop === "Corn" ? "Field Corn" : val.crop === "Soybeans" ? "Soybeans" : ""
            this.$refs.map.customerGeo.features.splice(ind, 1, feature)
        },
        nameChange(val, change) {
            let { feature, ind } = this.findFeature(val)
            feature.properties.title = val.name
            this.$refs.map.customerGeo.features.splice(ind, 1, feature)
        },
        magnify(val) {
            let ind = this.fields.findIndex(item => item.fieldId === val.fieldId)
            this.mapCoords = ind
        },
        checkCrop(val) {
            if (val === "Corn" || val === "Soybeans") return true
            else return false
        },
        editMapField(val) {
            let ind = this.fields.findIndex(item => item.fieldId === val)
            if (this.select) {
                this.$refs.settings.fieldsSelected.push(this.fields[ind])
            }
            else {
                if (this.checkCrop(this.fields[ind].crop)) {
                this.editField(this.fields[ind])
            }
                else {
                    this.showTabs = false
                }
            }
        },
        setFields(fields, set = true) {
                let allFields = fields.map((item,key) => {
                item.fieldId = key
                item.name = key + 1
                item.brand = "Peterson Farms Seed"
                item.product = ""
                item.settings = JSON.parse(JSON.stringify(this.fieldValues))
                item.drainage = item.settings.drainage
                item.irrigation = item.settings.irrigation
                item.tillage = item.settings.tillage
                item.done = false
                if (item.crop === "Field Corn") {
                    item.crop = "Corn"
                    item.trait = this.cornTraits[0]
                    item.gossWilt = "Average"
                    item.greensnap = "Low"
                    item.yieldEnvironment = []
                    item.rate = 20000
                    item.variableRate = false
                    item.rateRange = [20000, 30000]
                    item.avgRate = 20000
                }
                else if (item.crop === "Soybeans") {
                    item.crop = "Soybeans"
                    item.trait = this.soybeanTraits[0]
                    item.idc = "Average"
                    item.cyst = "No"
                    item.phytophthora = "Average"
                    item.whiteMold = "Low"
                    item.population = 140000
                }
                return item
            })
            if (set) {
                this.fields = allFields
            }
            else {
                return allFields
            }
        }
    }
}
</script>

<style lang="scss">
    .farm-market {
        h2 {
            color: #015a9c;
        }
    }
    .edit-plan {
        h1 {
            color: #015a9c;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
</style>